<template>
  <div class="view-home">
      <div class="seminars-block trainings">
        <div class="container">
          <nav class="breadcrumbs-block">
            <ul class="breadcrumbs-list">
              <li class="breadcrumbs-item"><router-link to="/">{{ $t('navbar.home') }}</router-link></li>
              <li class="breadcrumbs-item"><router-link to="/events">{{ $t('events-main') }}</router-link></li>
              <li class="breadcrumbs-item">{{ $t('interTrainingCourses') }}</li>
            </ul>
          </nav>
          <div class="seminars-title">
            {{ $t('interTrainingCourses') }}
          </div>
          <div class="events-title-line">
            <svg width="95" height="4" viewBox="0 0 95 4" fill="none" xmlns="http://www.w3.org/2000/svg">
              <line y1="2" x2="95" y2="2" stroke="#1090CB" stroke-width="4"/>
              <line y1="2" x2="95" y2="2" stroke="#42A7D6" stroke-width="4"/>
            </svg>
          </div>
          <div class="seminars-text">
            {{ $t('trainingTitlePage9') }}
          </div>
        </div>
      </div>
    <div class="trainings-img-back">
      <div class="container">
        <div class="seminars-list-block">
          <div class="trainings-filters">
            <div class="filter-date date-icon">
              <el-select v-model="value1" placeholder="Дата проведения" class="filter-date-select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
            <div class="filter-place">
              <el-select v-model="value2" placeholder="Место проведения" class="filter-date-select">
                <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="trainings-list">
            <div class="trainings-item" v-for="item in paginatedItems">
              <div class="trainings-img">
                <el-image
                    style="width: 100%; height: 100%"
                    :src="backUrl + item.img"
                    fit="fit"></el-image>
              </div>
              <div class="trainings-info">
                <div class="trainings-date">
                  {{ $t('dateOfEvent') }}: {{formattedDate(item.date_from, item.date_to)}}
                </div>
                <div class="trainings-title">
                  {{item[`title_${$i18n.locale}`]}}
                </div>
                <div class="trainings-text">
                  {{item[`description_${$i18n.locale}`]}}
                </div>
                <div class="trainings-address">
                  {{ $t('venue') }}: {{ item[`venue_${$i18n.locale}`] || item[`location_${$i18n.locale}`] }}
                </div>
                <router-link :to="'/one-training/international_course/' + item.id" class="trainings-link">
                  {{ $t('trainingTitlePage8') }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <nav class="seminars-pagination" v-if="totalPages > 1">
          <ul class="pagination">
            <li class="page-item">
              <span class="page-link" @click="prevPage">
                <svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M5.61188 10.875L6.875 9.61188L2.77208 5.5L6.875 1.38813L5.61187 0.125L0.236874 5.5L5.61188 10.875Z"
                      fill="black"/>
                </svg>
              </span>
            </li>
            <li class="page-item" v-for="page in totalPages"><span class="page-link" :class="{ 'active' : currentPage == page}" @click="currentPage=page">{{page}}</span></li>
            <li class="page-item">
              <span class="page-link" @click="nextPage">
                <svg width="8" height="11" viewBox="0 0 8 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M1.73187 0.125L0.46875 1.38812L4.57167 5.5L0.46875 9.61187L1.73187 10.875L7.10688 5.5L1.73187 0.125Z"
                      fill="black"/>
                </svg>
              </span
              ></li>
          </ul>
        </nav>
      </div>
    </div>

    <el-dialog
        :visible.sync="dialogVisible"
        :width="modalWidthTrainings"
    >
      <div class="trainings-modal-head">
        <img src="/images/modal-train-img.png" alt="" width="48" height="48">
        Программа повышения квалификации Южного федерального университета «Технологии профессиональной ориентации школьников»
      </div>
      <div class="trainings-modal-title">
        Запрос подробной информации
      </div>
      <div class="trainings-modal-text">
        Оставьте свой E-mail для того что бы мы отправили вам подробную информацию о курсе
      </div>
      <label class="trainings-modal-email">
        E-mail
        <el-input placeholder="Email" v-model="input"></el-input>
      </label>
      <div class="trainings-modal-btns">
        <button class="trainings-modal-cancel">
          Отмена
        </button>
        <button  class="trainings-modal-request">
          Запросить информацию
        </button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

export default {
  mounted() {
    if (window.innerWidth < 992) {
      this.modalWidthTrainings = "100%"
    }
    this.$http.get(window.API_ROOT + '/api/events/international-course/list')
        .then(res => {
          this.trainingList = res.body
          this.totalPages = Math.ceil(this.trainingList.length / this.itemsPerPage);
        })
  },
  filters: {

  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.trainingList.slice(startIndex, endIndex);
    }
  },
  methods: {
    formattedDate(date_from, date_to) {
      const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
      const dateFrom = new Date(date_from).toLocaleDateString('ru-RU', options);
      const dateTo = new Date(date_to).toLocaleDateString('ru-RU', options);
      return `${dateFrom} - ${dateTo} гг`;
    },
    prevPage() {
      if (this.currentPage == 1) return 1
      this.currentPage--
    },
    nextPage() {
      if (this.currentPage == this.totalPages) return this.totalPages
      this.currentPage++
    },
  },
  data() {
    return {
      itemsPerPage: 4,
      totalPages: 1,
      currentPage: 1,
      options: [{
        value: 'Option1',
        label: 'Option1'
      }, {
        value: 'Option2',
        label: 'Option2'
      }, {
        value: 'Option3',
        label: 'Option3'
      }, {
        value: 'Option4',
        label: 'Option4'
      }, {
        value: 'Option5',
        label: 'Option5'
      }],
      value1: '',
      value2: '',
      dialogVisible: false,
      modalWidthTrainings: "512px",
      input: "",
      trainingList: [],
      backUrl: window.API_ROOT + '/storage/',
    }
  }
}
</script>
<style>

</style>
